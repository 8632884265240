import { Text ,Html, ContactShadows, PresentationControls, Float, Environment, useGLTF } from "@react-three/drei";
import InnerScreen from "./InnerScreen";

export default function Experience() {
  const computer = useGLTF(
    "https://threejs-journey.com/resources/models/macbook_model.gltf"
  );

  return (
    <>
      <Environment preset="city" />

      {/* <color args={["#4D4F94"]} attach="background" /> */}
      
      <PresentationControls 
        global
        rotation={[0.13, 0.1, 0]}
        polar={[-0.4, 0.2]}
        azimuth={[-1, 0.75]}
        config={{mass:2, tention: 400}}
        snap={{mass:4, tension:400}}
      >
        <Float rotationIntensity={0.4}>
          <rectAreaLight 
            width={2.5}
            height={1.65}
            intensity={65}
            color={ '#D2C0FF' }
            rotation={[ 0.1, Math.PI,0 ]}
            position={[ 0, 0.55, - 1.15 ]}
          />
          <primitive 
            object={computer.scene} 
            position-y={-1.2} 
          >
            <Html 
              transform
              wrapperClass="htmlScreen"
              distanceFactor={1.17}
              position={[0, 1.56, - 1.4]}
              rotation-x={ - 0.256}
            >
              {/* <iframe src="https://code.edu.az/" /> */}
              <InnerScreen />
            </Html>
          </primitive>
          <Text
            font="./bangers-v20-latin-regular.woff"
            fontSize={.75}  
            position={[2, 0.75, 0.75]}
            rotation-y={- 1.25}
            maxWidth={2}
            textAlign="center"
          >
            Fidan Abdulla
          </Text>
        </Float>
      </PresentationControls>

      <ContactShadows 
        position-y={ - 1.4 } 
        opacity={ 0.4 }  
        scale={5}
        blur={2.4}
      />
    </>
  );
}
